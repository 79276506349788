
import { Component, Vue } from 'nuxt-property-decorator'
import EventBusMixin from '~/mixins/EventBusMixin.vue'

@Component({
  components: {
  MobileAppBanner: () => import('~/components/notify/banners/MobileAppBanner.vue'),
  Notify: () => import('@pp-frontend/authorization/component/Notify.vue'),
  },
  mixins: [
  EventBusMixin
  ]
  })
export default class extends Vue {
  notifyIsOpen: boolean = false

  scrollToNotify () {
    const element = document.getElementById('free-notifications')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  beforeMount () {
    this.$ga.updateStorage()
  }

  mounted () {
    this.initInAppMessageHandler()
  }

  initInAppMessageHandler () {
    if (this.$fireModule.messaging.isSupported()) {
      this.$fire.messaging.onMessage((e) => {
        const isConfirm = confirm(e.notification.body + ' Посмотреть?')

        if (isConfirm) {
          window.location.href = e.notification.click_action
        }
      })
    }
  }
}
